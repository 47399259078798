import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Player from "./video-player";
import NotFound from "./not-found";
import ImageViewer from "./image-viewer";

function App() {
	return (
		<div className="App">
			<link
				rel="stylesheet"
				href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
			></link>
			<BrowserRouter>
				<Routes>
					{/*

					 Development routes, comment when necessary
					
					<Route path="/upload" element={<Upload />} />
					<Route path="/watch-video/" element={<Play />} />
					<Route path="test" element={<Watch />}/>
					
					*/}
					<Route path="video/:videoId" element={<Player />} />
					<Route path="image/:imageId" element={<ImageViewer />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
