import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams, useParams } from "react-router-dom";

const ImageViewer = () => {
	// Assuming your images are in the 'public' folder
	let { imageId } = useParams();

	//const imageUrl = process.env.PUBLIC_URL + '/img/some-image.png';

	const [src, setSrc] = useState("");

	const [searchParams, setSearchParams] = useSearchParams();
	const [cookies, setCookie] = useCookies(["token"]);

	useEffect(() => {
		const element = document.getElementById(
			"whitelist-token",
		) as HTMLInputElement;
		let whitelistToken: string | undefined = undefined;
		if (element) {
			whitelistToken = element.value;
		}
		axios.get(`/api/image/${imageId}`).then((response) => {
			const currentToken = whitelistToken
				? whitelistToken
				: searchParams.get("token")
				? searchParams.get("token")
				: cookies.token;

			setSrc(
				response.data.domain +
					response.data.directory +
					response.data.filename +
					`?token=${currentToken}&imageId=${imageId}`,
			);

			console.log(
				response.data.domain +
					response.data.directory +
					response.data.filename +
					`?token=${currentToken}&imageId=${imageId}`,
			);
		});
	}, []);

	return (
		<div>
			{/* Use the image URL in the src attribute */}
			<img src={src} alt="Some Image" />
		</div>
	);
};

export default ImageViewer;
